/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
Note: any .css or .scss files included in the 'styles' directory
will be correctly compiled during `gulp serve` and `gulp`
 */
html, body {
  font-family: 'Roboto', 'Helvetica', sans-serif;
  margin: 0;
  padding: 0; }

.mdl-demo .mdl-layout__header-row {
  padding-left: 40px;
  z-index: 3;
}

.mdl-demo .mdl-layout.is-small-screen .mdl-layout__header-row h3 {
  font-size: inherit; }

.mdl-demo .mdl-layout__tab-bar-button {
  display: none; }

.mdl-demo .mdl-layout.is-small-screen .mdl-layout__tab-bar .mdl-button {
  display: none; }

.mdl-demo .mdl-layout:not(.is-small-screen) .mdl-layout__tab-bar,
.mdl-demo .mdl-layout:not(.is-small-screen) .mdl-layout__tab-bar-container {
  overflow: visible; }

.mdl-demo .mdl-layout__tab-bar-container {
  height: 64px; }

.mdl-demo .mdl-layout__tab-bar {
  padding: 0;
  padding-left: 16px;
  box-sizing: border-box;
  height: 100%;
  width: 100%; }

.mdl-demo .mdl-layout__tab-bar .mdl-layout__tab {
  height: 64px;
  line-height: 64px; }

.mdl-demo .mdl-layout__tab-bar .mdl-layout__tab.is-active::after {
  background-color: white;
  height: 4px; }


@media (min-width: 479px) {
  .mdl-demo main > .mdl-layout__tab-panel {
    padding: 8px;
    padding-top: 48px;
  }
}

.mdl-demo .mdl-card {
  height: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }

.mdl-demo .mdl-card > * {
  height: auto; }

.mdl-demo .mdl-card .mdl-card__supporting-text {
  margin: 40px;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 0;
  color: inherit;
  width: calc(100% - 80px); }

@media (max-width: 479px) {
  .mdl-demo .mdl-card .mdl-card__supporting-text {
    margin-top: 20px;
    margin-bottom: 0px;
  }
}

.mdl-demo.mdl-demo .mdl-card__supporting-text h4 {
  margin-top: 0;
  margin-bottom: 20px; }

.mdl-demo .mdl-card__actions {
  display: flex;
  margin: 0;
  padding: 4px 40px;
  color: inherit; }

.mdl-demo .mdl-card__actions a {
  color: #00BCD4;
  margin: 0; }

.mdl-demo .mdl-card__actions a:hover,
.mdl-demo .mdl-card__actions a:active {
  color: inherit;
  background-color: transparent; }

.mdl-demo .mdl-card__supporting-text + .mdl-card__actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.mdl-demo #add {
  position: absolute;
  right: 40px;
  top: 36px;
  z-index: 999; }

.mdl-demo .mdl-layout__content section:not(:last-of-type) {
  position: relative;
  margin-bottom: 48px; }

.mdl-demo section.section--center {
  position: relative;
  max-width: 860px; }

.mdl-demo #features section.section--center {
  max-width: 620px; }

.mdl-demo section > header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.mdl-demo section > .section__play-btn {
  min-height: 200px; }

.mdl-demo section > header > .material-icons {
  font-size: 3rem; }

.mdl-demo section > button {
  position: absolute;
  z-index: 99;
  top: 8px;
  right: 8px; }

.mdl-demo section .section__circle {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 1;
      -ms-flex-negative: 1;
          flex-shrink: 1; }

.mdl-demo section .section__text {
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  padding-top: 8px; }

.mdl-demo section .section__text h5 {
  font-size: inherit;
  margin: 0;
  margin-bottom: 0.5em; }

.mdl-demo section .section__text a {
  text-decoration: none; }

.mdl-demo section .section__circle-container > .section__circle-container__circle {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin: 8px 0; }

.mdl-demo section.section--footer .section__circle--big {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin: 8px 32px; }

.mdl-demo .is-small-screen section.section--footer .section__circle--big {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 8px 16px; }

.mdl-demo section.section--footer {
  padding: 64px 0;
  margin: 0 -8px -8px -8px; }

.mdl-demo section.section--center .section__text:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13); }

.mdl-demo .mdl-card .mdl-card__supporting-text > h3:first-child {
  margin-bottom: 24px; }

.mdl-demo .mdl-layout__tab-panel:not(#overview) {
  background-color: white; }

.mdl-demo #features section {
  margin-bottom: 72px; }

.mdl-demo #features h4, #features h5 {
  margin-bottom: 16px; }

.mdl-demo .toc {
  border-left: 4px solid #C1EEF4;
  margin: 24px;
  padding: 0;
  padding-left: 8px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }

.mdl-demo .toc h4 {
  font-size: 0.9rem;
  margin-top: 0; }

.mdl-demo .toc a {
  color: #4DD0E1;
  text-decoration: none;
  font-size: 16px;
  line-height: 28px;
  display: block; }

.mdl-demo .mdl-menu__container {
  z-index: 99; }




.mdl-dialog .mdl-progress { width: 100%; }
.mdl-data-table { width: 100%; }
.mdl-dialog {


  border: none;
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
  width: 280px; }
  .mdl-dialog__title {
    padding: 24px 24px 0;
    margin: 0;
    font-size: 1.5rem; }
  .mdl-dialog__actions {
    padding: 8px 8px 8px 24px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .mdl-dialog__actions > * {
      margin-right: 8px;
      height: 36px; }
      .mdl-dialog__actions > *:first-child {
        margin-right: 0; }
    .mdl-dialog__actions--full-width {
      padding: 0 0 8px 0; }
      .mdl-dialog__actions--full-width > * {
        height: 48px;
        -webkit-flex: 0 0 100%;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
        padding-right: 16px;
        margin-right: 0;
        text-align: right; }
  .mdl-dialog__content {
    padding: 20px 24px 24px 24px;
    color: rgba(0,0,0, 0.54); }


.current-page {
  top: 8px;
  position: relative;
  right: -16px;
  font-size: 12px;
  color: burlywood;
}

.toggle-keyboard{
  position: absolute;
  right: 0;
  top: 20px;
}

.mdl-layout__header canvas{
  position: absolute;
  z-index: 1;
}

.mdl-search-textfield {
  width: 75%;
}

.mdl-special-textfield {
  width: 16%;
  margin-left: 5%;
}

.mdl-medium-textfield {
  width: 44%;
  margin-right: 5%;
}

.mdl-small-textfield {
  width: 28%;
  margin-right: 3%;
}

.invfy-logo-container {
  background: #fdd8f2 !important;
}

#barcode-input-overlay {
  position: absolute;
  background: red;
  width: 100%;
  height: 36px;
  top: 16px;
  left: -26px;
  opacity: 0.5;
}